const translations = {
  common: {
    search: 'Search',
    signIn: 'Sign in',
    register: 'Register',
    myAccount: 'My Account',
    support: 'Support',
    followUs: 'Follow us on X/Twitter',
    proMembership: 'Pro Membership',
    new: 'New',
    proMember: 'Pro Member',
    upgrade: 'Upgrade',
    subscribe: 'Subscribe',
    manage: 'Manage',
    cancel: 'Cancel',
    close: 'Close',
    or: 'Or',
    email: 'Email address',
    continue: 'Continue',
    submit: 'Submit',
    billing: 'Billing',
    sort: 'Sort',
    language: 'Language',
    selectLanguage: 'Select Language',
    searchPlaceholder: 'Search...',
    cards: 'Cards',
    previous: 'Previous',
    next: 'Next',
    pagination: 'Pagination',
    back: 'Back',
    backToSets: 'Back to Sets',
    error: 'An error occurred. Please try again.',
    home: 'Home',
    sets: 'Sets',
  },
  languages: {
    default: 'Default',
    any: 'Any',
    english: 'English',
    spanish: 'Spanish',
    french: 'French',
    german: 'German',
    italian: 'Italian',
    portuguese: 'Portuguese',
    japanese: 'Japanese',
    korean: 'Korean',
    russian: 'Russian',
    simplified_chinese: 'Simplified Chinese',
    traditional_chinese: 'Traditional Chinese',
    hebrew: 'Hebrew',
    latin: 'Latin',
    ancient_greek: 'Ancient Greek',
    arabic: 'Arabic',
    sanskrit: 'Sanskrit',
    phyrexian: 'Phyrexian',
    quenya: 'Quenya',
  },
  filters: {
    show: '✨ Show Pro Filters',
    hide: '✨ Hide Pro Filters',
  },
  search: {
    showing_start: 'Showing',
    showing_to: 'to',
    showing_of: 'of',
    showing_results: 'results',
    noResults: 'No results found.',
    useSearchTip: 'Use the search at the top of the page to look up a card.',
  },
  sort: {
    nameAsc: 'Name (A to Z)',
    nameDesc: 'Name (Z to A)',
    cmcAsc: 'Mana Value (Low to High)',
    cmcDesc: 'Mana Value (High to Low)',
    usdAsc: 'Price (Low to High)',
    usdDesc: 'Price (High to Low)',
    rarityAsc: 'Rarity (Common to Mythic)',
    rarityDesc: 'Rarity (Mythic to Common)',
    releasedDesc: 'Release Date (Newest First)',
    releasedAsc: 'Release Date (Oldest First)',
  },
  pages: {
    search: {
      heading: 'Search',
    },
    sets: {
      heading: 'Card Sets',
      latestSets: 'Latest Sets',
      upcomingSets: 'Upcoming Sets',
      allSets: 'All Sets',
      noResults: 'No results found.',
    },
    set: {
      notFound: 'Set not found.',
      noCards: 'No cards found in this set.',
      addAll: 'Add {{count}} Cards to Print List',
      addedToList: 'Added {{count}} cards to Print List',
    },
    tokens: {
      heading: 'Tokens',
    },
    emblems: {
      heading: 'Emblems',
    },
    import: {
      heading: 'Import cards to Print List',
      description: 'Input the names of all the cards you want to add to the Print List.',
    },
    topProxies: {
      heading: 'Top Proxies',
      description: 'Here are the top 50 most popular Magic: The Gathering proxy cards on MTG Proxies.',
    },
  },
  theme: {
    light: 'Light',
    dark: 'Dark',
    toggle: 'Toggle theme',
  },
  cta: {
    title: 'Ready to dive in?',
    subtitle: 'Start proxying cards today.',
    searchByCard: 'Search by card',
    searchBySet: 'Search by set',
  },
  nav: {
    proxyCards: 'Proxy Cards',
    sets: 'Sets',
    tokens: 'Tokens',
    emblems: 'Emblems',
    import: 'Import',
    topProxies: 'Top Proxies',
    customCards: 'Custom Cards',
    deckBuilder: 'Deck Builder',
    buildDeck: 'Build a Deck',
    publicDecks: 'Public Decks',
    favoriteDeck: 'My Favorite Decks',
    articles: 'Articles',
    feedback: 'Share Feedback',
  },
  seo: {
    home: {
      title: 'Print Free Magic: The Gathering Proxy Cards | MTG Proxies',
      description:
        'Discover high-quality Magic: The Gathering proxy cards for playtesting and casual gameplay. Browse our extensive collection of MTG proxies including commanders, planeswalkers, and rare cards. Create custom decks without breaking your budget - print and play today!',
    },
    sets: {
      title: 'All Magic: The Gathering Sets | Print MTG Proxies by Set | MTG Proxies',
      description: 'Explore our comprehensive collection of Magic: The Gathering card sets from Alpha to the latest releases. Find and print high-quality MTG proxies organized by expansion set for your casual gameplay.',
    },
    setDetail: {
      title: '{{setName}} Cards | Print High-Quality MTG Proxies | MTG Proxies',
      description: 'View every card from {{setName}} in high resolution. Create and print MTG proxies from this set for playtesting and casual play. Find your favorite cards and add them to your collection today.',
    },
    tokens: {
      title: 'MTG Token Proxies | Printable Magic: The Gathering Tokens | MTG Proxies',
      description: 'Complete your Magic: The Gathering gameplay experience with our extensive token proxy collection. Find creature tokens, clues, treasures, food, and more - all printable for immediate use in your casual games.',
    },
    emblems: {
      title: 'MTG Emblem Proxies | Printable Planeswalker Emblems | MTG Proxies',
      description: 'Access our complete collection of Magic: The Gathering emblem proxies for planeswalkers and special abilities. Print high-quality emblems to enhance your gameplay experience and keep track of powerful ongoing effects.',
    },
    search: {
      title: 'Search Magic Cards | Find & Print MTG Proxies | MTG Proxies',
      description: 'Effortlessly find and print MTG proxy cards. Use our search tool to access an extensive database and enhance your Magic: The Gathering deck with proxies.',
    },
    support: {
      title: 'MTG Proxies Support | Help with Proxy Cards | MTG Proxies',
      description: 'Get help with our MTG proxy printing service, report issues, or request new features. Our dedicated support team is ready to assist you with any questions about Magic: The Gathering proxies for casual play.',
    },
    articles: {
      title: 'MTG Proxy Articles & Guides | Magic: The Gathering Resources | MTG Proxies',
      description: 'Read expert guides, tutorials, and opinion pieces about Magic: The Gathering proxy cards. Learn about formats, deck building strategies, new sets, and best practices for creating and using MTG proxies.',
    },
    import: {
      title: 'Import Deck Lists | Bulk MTG Proxy Creator | MTG Proxies',
      description: 'Quickly convert your deck lists into printable Magic: The Gathering proxies with our bulk import tool. Paste card names from any source and instantly create proxy cards for casual play and playtesting.',
    },
    topProxies: {
      title: 'Most Popular MTG Proxy Cards | Top Magic: The Gathering Proxies | MTG Proxies',
      description: 'Discover the most frequently printed Magic: The Gathering proxy cards. Perfect for finding inspiration for your next deck.',
    },
    customCards: {
      title: 'Design Custom MTG Cards | Personalized Magic Proxies | MTG Proxies',
      description: 'Design personalized Magic: The Gathering proxy cards with our custom card creator. Modify existing cards or create entirely new ones for casual play and creative expression with friends.',
    },
    login: {
      title: 'Sign In | Access Your MTG Proxy Collection | MTG Proxies',
      description: 'Access your saved Magic: The Gathering proxy collections, custom decks, and personalized settings. Sign in to enjoy a seamless MTG proxy printing experience tailored to your preferences.',
    },
    register: {
      title: 'Create Free Account | Save Your MTG Proxies | MTG Proxies',
      description: 'Create a free account to save your Magic: The Gathering proxy collections, track your favorite cards, build custom decks, and access personalized features for an enhanced proxy printing experience.',
    },
    termsOfService: {
      title: 'Terms of Service | MTG Proxies Legal Information | MTG Proxies',
      description: 'Review our comprehensive terms of service regarding the use of MTG Proxies for casual play and playtesting. Learn about our policies on Magic: The Gathering proxy cards and responsible usage guidelines.',
    },
    privacyPolicy: {
      title: 'Privacy Policy | How MTG Proxies Protects Your Data | MTG Proxies',
      description: 'Learn how we protect your data while using our Magic: The Gathering proxy service. Our privacy policy details how we handle information when you create, save, and print MTG proxies for casual play.',
    },
    account: {
      title: 'My Account | Manage Your MTG Proxy Settings | MTG Proxies',
      description: 'Update your profile settings, pro membership, and more.',
    },
    proMembership: {
      title: 'Pro Membership | Premium MTG Proxy Features & Benefits | MTG Proxies',
      description: 'Upgrade to Pro Membership for an enhanced Magic: The Gathering proxy experience with advanced printing options, ad-free browsing, unlimited deck storage, and exclusive card customization features.',
    },
    deckBuilder: {
      title: 'MTG Deck Builder | Create & Print Proxy Decks | MTG Proxies',
      description: 'Build and organize your Magic: The Gathering decks with our intuitive deck builder. Create custom commander, standard, or casual decks, then easily print high-quality proxies for playtesting and casual play.',
    },
    deckEditor: {
      title: 'MTG Deck Editor | Customize & Optimize Proxy Decks | MTG Proxies',
      description: 'Fine-tune your Magic: The Gathering decks with our powerful editor. Modify card quantities, add sideboard options, and optimize your strategy before printing high-quality MTG proxies for casual gameplay.',
    },
    publicDecks: {
      title: 'Public MTG Decks | Browse & Print Community Proxy Decks | MTG Proxies',
      description: 'Discover winning strategies and creative deck ideas from the Magic: The Gathering community. Browse public decks by format, commander, or theme, then print proxies to test them in your casual games.',
    },
  },
  pro: {
    pricing: {
      title: 'Level Up Your MTG Proxy Experience',
      subtitle: 'Go Pro and unlock a premium, ad-free experience with exclusive features that take your proxying to the next level!',
      monthlyPlan: 'Monthly Pro Membership',
      yearlyPlan: 'Yearly Pro Membership',
      monthlyPrice: '$5.00 per month',
      yearlyPrice: '$40.00 per year',
      subscribeMonthly: 'Get Started Now',
      subscribeYearly: 'Save Big Yearly',
      signInToSubscribe: 'Sign in to Unlock Pro',
      manageSubscription: 'Manage Your Pro Benefits',
      nextBillingDate: 'Your next payment date',
      benefitsEndDate: 'Pro access until',
      viewInvoice: 'View Receipt',
      changePlan: 'Switch Your Plan',
      reactivate: 'Restore Pro Benefits',
      active: 'Active',
      cancelled: 'Cancelled',
      freeTrial: 'Free Trial',
      freeTrialEnds: 'Free Trial Ends',
      startFreeTrial: 'Start 7-Day Free Trial',
      signInForFreeTrial: 'Sign in for Free 7-Day Trial',
      subscribe: 'Subscribe Now',
      current: 'Current',
    },
    benefits: {
      title: 'Why Go Pro with MTG Proxies?',
      subtitle: "Join hundreds of players who've enhanced their proxying experience with these exclusive Pro features!",
      noAds: {
        title: 'Clean, Ad-Free Experience',
        description: 'Say goodbye to interruptions! Enjoy a pristine interface without ads, banners, or pop-ups. Pure MTG proxy goodness.',
      },
      darkMode: {
        title: 'Epic Dark Mode',
        description: 'Easy on the eyes and looking sleek! Perfect for night owls and marathon proxy sessions in any lighting condition.',
      },
      advancedSearch: {
        title: 'Pro-Level Search Magic',
        description: 'Find exactly what you need with powerful filters! Filter by color identity, rarity, card type, and more to build the perfect deck.',
      },
      multipleDecks: {
        title: 'Unlimited Deck Building',
        description: "Deck builder's paradise! Create and save as many decks as you want – from casual fun to competitive powerhouses.",
      },
      futureFeatures: {
        title: 'First Access to New Features',
        description: "Be at the cutting edge! Pro members always get first dibs on exciting new features as soon as they're released.",
      },
      cancelAnytime: {
        title: 'Zero Risk, All Reward',
        description: 'Complete freedom! No long-term contracts or hidden fees. Cancel with a single click whenever you want.',
      },
      prioritySupport: {
        title: 'VIP Support Access',
        description: 'Skip the line! Your questions and concerns get priority treatment with our dedicated support team.',
      },
    },
    features: {
      unlimitedDecks: 'Unlimited deck creation',
      noAds: 'Ad-free proxying experience',
      prioritySupport: 'VIP support access',
      darkMode: 'Sleek dark mode interface',
      advancedSearch: 'Pro search filters & tools',
      futureFeatures: 'Exclusive upcoming features',
      cancelAnytime: 'Hassle-free cancellation',
    },
    promotion: {
      title: 'Unleash Unlimited Deck Creation',
      description: 'Break free from limitations! Upgrade to Pro now and build as many decks as your imagination allows.',
      customizeTitle: 'Transform Cards with AI Magic',
      customizeDescription: 'Upgrade to Pro and create up to 5 custom card variations daily using our cutting-edge AI technology!',
      banner: 'Try Pro free for 7 days! No ads, dark mode, unlimited decks, and more.',
      tryNow: 'Try Now',
    },
    account: {
      title: 'Your Pro Membership',
      description: 'Manage your premium benefits',
      subscribeToPro: 'Upgrade to Pro Now',
    },
  },
  auth: {
    signIn: {
      title: 'Sign in to your account',
      withGoogle: 'Continue with Google',
      withEmail: 'Or continue with',
      sendMagicLink: 'Send Magic Link',
      noAccount: "Don't have an account?",
      checkEmail: 'Check your email for a link to sign in',
      errors: {
        failed: 'Failed to sign in',
        failedGoogle: 'Failed to sign in with Google',
        failedEmail: 'Failed to sign in with email',
        noEmail: 'No email found',
      },
      terms: 'By logging in, I confirm that I have read and agree to the',
    },
    register: {
      title: 'Create your account',
      haveAccount: 'Already have an account?',
      terms: 'By creating an account, I confirm that I have read and agree to the',
    },
    common: {
      termsOfService: 'Terms of Service',
      privacyPolicy: 'Privacy Policy',
      and: 'and',
    },
  },
  home: {
    hero: {
      title: 'Proxy',
      rotatingWords: ['cards', 'tokens', 'emblems', 'planeswalkers', 'commanders', 'lands', 'creatures', 'artifacts', 'enchantments', 'instants'],
      description: 'From Black Lotus to the latest mythics, your dream deck is within reach, regardless of cost.',
      search: {
        cards: {
          tab: 'Cards',
          placeholder: 'Search for any Magic card...',
          error: 'Failed to fetch random cards',
        },
        sets: {
          tab: 'Sets',
          placeholder: 'Search for any Magic set...',
        },
        tokens: {
          tab: 'Tokens',
          placeholder: 'Search for any Magic token...',
        },
        button: 'Search',
        hint: {
          cards: 'Press Enter or click Search to find your cards',
          sets: 'Press Enter or click Search to find your sets',
          tokens: 'Press Enter or click Search to find your tokens',
        },
        aria: {
          searchIcon: 'Search icon',
          searchInput: {
            cards: 'Search for cards',
            sets: 'Search for sets',
            tokens: 'Search for tokens',
          },
        },
      },
      images: {
        cardAlt: 'Magic card image',
      },
      processedCount: "🎉 We've processed {{count}} free proxies for the community! 🎉",
    },
    features: {
      section1: {
        badge: 'Print Proxies',
        title: 'Print MTG Proxy Cards',
        subtitle: 'Add cards to your print list, print them out, and use them in your decks today!',
        features: [
          {
            name: 'Add card to your print list',
            description: 'Search for any card using the Search page or browse by set and add the cards you want to your print list.',
          },
          {
            name: 'Print your cards',
            description: 'Once you have added all the cards you want to your print list, you can print them out and use them in your decks.',
          },
          {
            name: 'Use them in your decks',
            description: 'Once you have printed out your cards, you can use them in your decks. Cut them out and tape them to a basic land or use a sleeve with a basic land inside.',
          },
        ],
        images: {
          cartScreenshot: 'Cart screenshot showing how to add cards to your print list',
        },
      },
      section2: {
        badge: 'Deck Builder',
        title: 'Build and manage Magic: The Gathering Decks',
        subtitle: 'Create and manage your own decks, or browse public decks created by the community.',
        features: [
          {
            name: 'Manage your MTG Decks',
            description: 'All users can create and manage one MTG deck. Pro members can create an unlimited number of decks.',
          },
          {
            name: 'Print your cards',
            description: 'Once you have added all the cards you want to your deck, you can add them to your Print List all at once.',
          },
          {
            name: 'Browse Public Decks',
            description: 'Add entire decks made by the community to your Print List so you can easily play test new decks.',
          },
        ],
        images: {
          deckBuilderScreenshot: 'Deck Builder interface showing how to manage your decks',
        },
      },
    },
  },
  footer: {
    copyright: 'MTG Proxies, All rights reserved.',
    disclaimer: 'MTG Proxies is not produced, endorsed, supported, or affiliated with Wizards of the Coast.',
    affiliate: 'As a TCGplayer affiliate partner, MTG Proxies may earn a commission on qualifying purchases.',
    builtBy: 'Built by',
    minnerLabs: 'Minner Labs',
    privacyPolicy: 'Privacy Policy',
    termsOfService: 'Terms of Service',
    support: 'Support',
  },
  advancedFilters: {
    colors: 'Colors',
    rarity: 'Rarity',
    cardTypes: 'Card Types',
    format: 'Format',
    manaValue: 'Mana Value',
    cardName: 'Card Name',
    cardText: 'Card Text',
    specialCharacteristics: 'Special Characteristics',
    activeFilters: 'Active Filters',
    clearAll: 'Clear All',
    applyFilters: 'Apply Filters',
    searchCardName: 'Search card name...',
    searchCardText: 'Search card text...',
    namePrefix: 'Name: ',
    manaPrefix: 'Mana: ',
    textPrefix: 'Text: ',
    removeFromFilter: '✕',
    selectLanguage: 'Select language',
  },
  card: {
    addToPrintList: 'Add to Print List',
    removeFromPrintList: 'Remove from Print List',
    cardsInPrintList: 'card in Print List',
    cardsInPrintListPlural: 'cards in Print List',
    buyOn: 'Buy on TCGplayer',
    buyFor: 'Buy for ',
    viewDetails: 'View Details',
    type: 'Type',
    manaCost: 'Mana Cost',
    rarity: 'Rarity',
    artist: 'Artist',
    collectorNumber: 'Collector Number',
    oracleText: 'Oracle Text',
    youMightAlsoLike: 'You might also like...',
    noRecommendations: 'No recommendations available at this moment.',
    details: 'Details',
    rulings: 'Rulings',
    legality: 'Legality',
    share: 'Share',
    favorite: 'Favorite',
    noRulings: 'No rulings available for this card.',
    noLegalityInfo: 'No legality information available for this card.',
    published: 'Published',
    legal: 'Legal',
    restricted: 'Restricted',
    banned: 'Banned',
    format: 'Format',
    status: 'Status',
    viewMore: 'View More',
    viewLess: 'View Less',
    customizeWithAI: 'Customize with AI',
    otherVersions: 'Unique Prints',
    showUniqueVersions: 'Show Unique Prints',
    hideUniqueVersions: 'Hide Unique Prints',
  },
  cart: {
    title: 'Print List',
    closePanel: 'Close panel',
    empty: 'No proxies in the print list.',
    addPadding: 'Add padding',
    paperSize: 'Paper size',
    scale: 'Scale',
    clearCardList: 'Clear card list',
    print: 'Print',
    success: 'Success!',
  },
  setsList: {
    showing: 'Showing',
    to: 'to',
    of: 'of',
    results: 'results',
    searchPlaceholder: 'Search...',
  },
  customizeCardModal: {
    title: 'Customize Card with AI',
    description: 'Describe how you want to customize this card. The AI will generate a new version while maintaining the card frame and structure.',
    buttonText: 'Customize with AI',
    placeholder: 'Describe your custom card variation...',
    generateButton: 'Generate Custom Card',
    generatingButton: 'Generating...',
    addToPrintListButton: 'Add to Print List',
    successMessage: 'Added custom card to print list',
    remainingGenerationsOne: '{{count}} generation remaining today',
    remainingGenerationsOther: '{{count}} generations remaining today',
    error: {
      failed: 'Failed to generate custom card',
    },
  },
  // Add more translation keys as needed
  feedback: {
    buttonLabel: 'Feedback',
    title: 'Share Your Feedback',
    description: 'We value your input to improve MTG Proxies. Please share your thoughts with us.',
    categoryLabel: 'Category',
    categories: {
      bug: 'Bug Report',
      feature: 'Feature Request',
      improvement: 'Improvement Suggestion',
      general: 'General Feedback',
      other: 'Other',
    },
    messageLabel: 'Your Feedback',
    messagePlaceholder: 'Please share your thoughts, suggestions, or report an issue...',
    ratingLabel: 'Rate Your Experience (Optional)',
    emailLabel: 'Email (Optional)',
    emailPlaceholder: 'your@email.com',
    emailHelp: "Provide your email if you'd like us to follow up with you.",
    submitting: 'Submitting...',
    submitButton: 'Submit Feedback',
    successMessage: 'Thank you for your feedback!',
    errorMessage: 'Failed to submit feedback. Please try again.',
  },
};

export default translations;
