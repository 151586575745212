import { useMutation } from '@tanstack/react-query';
import { useUserStore } from './useUser';
import { toast } from 'sonner';
import { useTranslation } from './useTranslation';

export const useFeedback = () => {
  const { t } = useTranslation();
  const { user } = useUserStore();

  const submitFeedback = async (feedbackData) => {
    // Get browser metadata
    const metadata = {
      url: window.location.href,
      userAgent: navigator.userAgent,
      language: navigator.language,
      timestamp: new Date().toISOString(),
    };

    // Add user info if available
    const payload = {
      ...feedbackData,
      metadata,
    };

    if (user) {
      payload.userId = user.uid;
      payload.userEmail = user.email;
    }

    const response = await fetch('/api/feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to submit feedback');
    }

    return response.json();
  };

  const feedbackMutation = useMutation({
    mutationFn: submitFeedback,
    onSuccess: () => {
      toast.success(t('feedback.successMessage') || 'Thank you for your feedback!');
    },
    onError: (error) => {
      console.error('Error submitting feedback:', error);
      toast.error(t('feedback.errorMessage') || 'Failed to submit feedback. Please try again.');
    },
  });

  return {
    submitFeedback: feedbackMutation.mutate,
    isSubmitting: feedbackMutation.isPending,
    isSuccess: feedbackMutation.isSuccess,
    isError: feedbackMutation.isError,
    error: feedbackMutation.error,
  };
};
