import { useState } from 'react';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { useFeedback } from '@/hooks/useFeedback';
import { useUserStore } from '@/hooks/useUser';
import { useTranslation } from '@/hooks/useTranslation';
import { MessageSquare } from 'lucide-react';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';

const FEEDBACK_CATEGORIES = [
  { value: 'bug', label: 'Bug Report' },
  { value: 'feature', label: 'Feature Request' },
  { value: 'improvement', label: 'Improvement Suggestion' },
  { value: 'general', label: 'General Feedback' },
  { value: 'other', label: 'Other' },
];

const RATING_OPTIONS = [1, 2, 3, 4, 5];

export default function FeedbackDialog({ trigger, className }) {
  const { t } = useTranslation();
  const { user } = useUserStore();
  const { submitFeedback, isSubmitting } = useFeedback();

  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState('general');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState(user?.email || '');
  const [rating, setRating] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    submitFeedback({
      category,
      message,
      userEmail: email || null,
      rating,
    });

    // Reset form after successful submission
    if (!isSubmitting) {
      setTimeout(() => {
        setMessage('');
        setRating(null);
        setOpen(false);
      }, 1000);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {trigger || (
          <Button variant='ghost' className={className} title={t('feedback.buttonLabel') || 'Feedback'}>
            <QuestionMarkCircleIcon className='h-6 w-6' />
            <span className='hidden 2xl:inline'>{t('feedback.buttonLabel') || 'Feedback'}</span>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className='sm:max-w-[425px]'>
        <DialogHeader>
          <DialogTitle>{t('feedback.title') || 'Share Your Feedback'}</DialogTitle>
          <DialogDescription>{t('feedback.description') || 'We value your input to improve MTG Proxies. Please share your thoughts with us.'}</DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className='space-y-4 pt-4'>
          <div className='space-y-2'>
            <Label htmlFor='category'>{t('feedback.categoryLabel') || 'Category'}</Label>
            <select
              id='category'
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className='flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 md:text-sm focus:ring-1 focus:ring-ring focus:border-transparent'
              required
            >
              {FEEDBACK_CATEGORIES.map((cat) => (
                <option key={cat.value} value={cat.value}>
                  {t(`feedback.categories.${cat.value}`) || cat.label}
                </option>
              ))}
            </select>
          </div>

          <div className='space-y-2'>
            <Label htmlFor='message'>{t('feedback.messageLabel') || 'Your Feedback'}</Label>
            <Textarea id='message' value={message} onChange={(e) => setMessage(e.target.value)} placeholder={t('feedback.messagePlaceholder') || 'Please share your thoughts, suggestions, or report an issue...'} className='min-h-[100px]' required />
          </div>

          <div className='space-y-2'>
            <Label htmlFor='rating'>{t('feedback.ratingLabel') || 'Rate Your Experience (Optional)'}</Label>
            <div className='flex space-x-2'>
              {RATING_OPTIONS.map((value) => (
                <Button key={value} type='button' variant={rating === value ? 'default' : 'outline'} className='h-10 w-10 p-0' onClick={() => setRating(value)}>
                  {value}
                </Button>
              ))}
            </div>
          </div>

          {!user && (
            <div className='space-y-2'>
              <Label htmlFor='email'>{t('feedback.emailLabel') || 'Email (Optional)'}</Label>
              <Input id='email' type='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t('feedback.emailPlaceholder') || 'your@email.com'} />
              <p className='text-xs text-muted-foreground'>{t('feedback.emailHelp') || "Provide your email if you'd like us to follow up with you."}</p>
            </div>
          )}

          <DialogFooter>
            <Button type='submit' disabled={isSubmitting || !message}>
              {isSubmitting ? t('feedback.submitting') || 'Submitting...' : t('feedback.submitButton') || 'Submit Feedback'}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
