import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Textarea } from '@/components/ui/textarea';
import { useGenerateCardProxy, useRemainingGenerations } from '@/hooks/useGenerateCardProxy';
import { useTranslation } from '@/hooks/useTranslation';
import { Wand2 } from 'lucide-react';
import Link from 'next/link';
import { useState } from 'react';
import { toast } from 'sonner';
import { useCart } from '../hooks/useCart';
import { useUserStore } from '../hooks/useUser';
import CardImage from './CardImage';

interface CartContextType {
	cartOpened: boolean;
	openCart: () => void;
	closeCart: () => void;
	cart: Array<{ quantity: number; details: any }>;
	updateQuantity: (cardId: string, quantity: number) => void;
	addCardToCart: (card: any) => void;
	clearCart: () => void;
	getNumberOfItemsInCart: () => number;
	getQuantityOfItem: (cardId: string) => number;
	addCardsToCart: (cards: Array<{ quantity: number; details: any }>) => void;
}

interface CustomizeCardModalProps {
	card: any;
	onCustomize: (customImage: string) => void;
}

export default function CustomizeCardModal({ card, onCustomize }: CustomizeCardModalProps) {
	const [customText, setCustomText] = useState('');
	const [generatedImage, setGeneratedImage] = useState<string | null>(null);
	const [isOpen, setIsOpen] = useState(false);
	const { customerDetails, isProMemberActive } = useUserStore();
	const cart = useCart() as CartContextType | null;
	const isPro = isProMemberActive;
	const { t } = useTranslation();

	const { mutate: generateCardProxy, isPending: isGenerating } = useGenerateCardProxy();
	const { data: remainingGenerations } = useRemainingGenerations(customerDetails?.stripeCustomerId);

	const handleGenerate = () => {
		if (!isPro || !cart || !customerDetails?.email) return;

		generateCardProxy(
			{
				cardId: card.id,
				originalImage: card.image_uris.large,
				customText,
				userEmail: customerDetails.email,
			},
			{
				onSuccess: (data) => {
					setGeneratedImage(data.imageUrl);
					onCustomize(data.imageUrl);
				},
				onError: (error) => {
					console.error('Error generating custom card:', error);
					toast.error(error instanceof Error ? error.message : t('customizeCardModal.error.failed'));
				},
			}
		);
	};

	const handleAddToList = () => {
		if (!generatedImage || !cart) return;

		cart.addCardToCart({
			...card,
			image_uris: {
				...card.image_uris,
				large: generatedImage
			},
			isCustom: true,
			customImage: generatedImage,
			originalCardId: card.id,
			id: `${card.id}-custom-${Date.now()}`
		});

		setIsOpen(false);
		toast.success(t('customizeCardModal.successMessage'));
	};

	if (!isPro) {
		return (
			<Dialog>
				<DialogTrigger asChild>
					<Button variant="outline" className="w-full">
						<Wand2 className="w-4 h-4 mr-2" />
						{t('pro.promotion.customizeTitle')}
					</Button>
				</DialogTrigger>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>{t('pro.promotion.customizeTitle')}</DialogTitle>
						<DialogDescription>
							{t('pro.promotion.customizeDescription')}
						</DialogDescription>
					</DialogHeader>
					<div className="flex justify-center py-4">
						<Button asChild variant="primary">
							<Link href="/pro-membership">{t('common.upgrade')} {t('common.proMembership')}</Link>
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		);
	}

	return (
		<Dialog open={isOpen} onOpenChange={setIsOpen}>
			<DialogTrigger asChild>
				<Button variant="outline" className="w-full">
					<Wand2 className="w-4 h-4 mr-2" />
					{t('customizeCardModal.buttonText')}
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle>{t('customizeCardModal.title')}</DialogTitle>
					<DialogDescription>
						{t('customizeCardModal.description')}
					</DialogDescription>
				</DialogHeader>
				<div className="grid gap-4 py-4">
					<div className="grid gap-2">
						<Textarea
							placeholder={t('customizeCardModal.placeholder')}
							value={customText}
							onChange={(e) => setCustomText(e.target.value)}
						/>
						{remainingGenerations !== null && (
							<p className="text-sm text-muted-foreground">
								{remainingGenerations === 1 ? t('customizeCardModal.remainingGenerationsOne', { count: remainingGenerations ?? 0 }) : t('customizeCardModal.remainingGenerationsOther', { count: remainingGenerations ?? 0 })}
							</p>
						)}
					</div>
					{generatedImage && (
						<div className="mt-4">
							<CardImage src={generatedImage} alt={card.name} className={undefined} />
						</div>
					)}
				</div>
				<DialogFooter>
					{generatedImage ? (
						<Button
							type="submit"
							variant="primary"
							onClick={handleAddToList}
						>
							{t('customizeCardModal.addToPrintListButton')}
						</Button>
					) : (
						<Button
							type="submit"
							onClick={handleGenerate}
							disabled={isGenerating || !customText.trim() || remainingGenerations === 0}
						>
							{isGenerating ? t('customizeCardModal.generatingButton') : t('customizeCardModal.generateButton')}
						</Button>
					)}
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}