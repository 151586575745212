const translations = {
  common: {
    search: 'Rechercher',
    signIn: 'Se connecter',
    register: "S'inscrire",
    myAccount: 'Mon compte',
    support: 'Support',
    followUs: 'Suivez-nous sur X/Twitter',
    proMembership: 'Abonnement Pro',
    new: 'Nouveau',
    proMember: 'Membre Pro',
    upgrade: 'Améliorer',
    subscribe: "S'abonner",
    manage: 'Gérer',
    cancel: 'Annuler',
    close: 'Fermer',
    or: 'Ou',
    email: 'Adresse e-mail',
    continue: 'Continuer',
    submit: 'Envoyer',
    billing: 'Facturation',
    sort: 'Trier',
    language: 'Langue',
    selectLanguage: 'Sélectionner la langue',
    searchPlaceholder: 'Rechercher...',
    cards: 'Cartes',
    previous: 'Précédent',
    next: 'Suivant',
    pagination: 'Pagination',
    back: 'Retour',
    backToSets: 'Retour aux éditions',
    error: 'Une erreur est survenue. Veuillez réessayer.',
    home: 'Accueil',
    sets: 'Éditions',
  },
  theme: {
    light: 'Clair',
    dark: 'Sombre',
    toggle: 'Changer de thème',
  },
  cta: {
    title: 'Prêt à commencer ?',
    subtitle: "Commencez à créer des proxies dès aujourd'hui.",
    searchByCard: 'Rechercher par carte',
    searchBySet: 'Rechercher par édition',
  },
  nav: {
    proxyCards: 'Cartes Proxy',
    sets: 'Éditions',
    tokens: 'Jetons',
    emblems: 'Emblèmes',
    import: 'Importer',
    topProxies: 'Top Proxies',
    customCards: 'Cartes Personnalisées',
    deckBuilder: 'Constructeur de Deck',
    buildDeck: 'Construire un Deck',
    publicDecks: 'Decks Publics',
    favoriteDeck: 'Mes Decks Favoris',
    articles: 'Articles',
  },
  seo: {
    home: {
      title: 'MTG Proxies - Cartes proxy Magic: The Gathering',
      description: 'Accédez à une vaste bibliothèque de cartes proxy Magic: The Gathering. Visualisez et imprimez facilement des images de cartes MTG pour le jeu occasionnel. Commencez à construire le deck de vos rêves maintenant !',
    },
    sets: {
      title: 'Éditions de Cartes - MTG Proxies',
      description: 'Parcourez toutes les éditions MTG et imprimez des proxies pour votre deck.',
    },
    tokens: {
      title: 'Jetons - MTG Proxies',
      description: 'Améliorez vos parties de MTG avec une variété de jetons proxy.',
    },
    emblems: {
      title: 'Emblèmes - MTG Proxies',
      description: 'Imprimez des cartes proxy Emblème MTG pour votre deck.',
    },
    search: {
      title: 'Rechercher des Cartes MTG - MTG Proxies',
      description: 'Trouvez et imprimez facilement des cartes proxy MTG. Utilisez notre outil de recherche pour accéder à une vaste base de données et améliorez votre deck Magic: The Gathering avec des proxies.',
    },
    support: {
      title: 'Support - MTG Proxies',
      description: 'Si vous avez des questions, des commentaires ou souhaitez ajouter un jeton/emblème, envoyez-nous un e-mail.',
    },
    articles: {
      title: 'Articles - MTG Proxies',
      description: 'Articles écrits sur Magic: The Gathering et les cartes proxy.',
    },
    import: {
      title: 'Importer - MTG Proxies',
      description: 'Importez une liste de cartes pour imprimer vos proxies Magic: The Gathering.',
    },
    topProxies: {
      title: 'Top Proxies - MTG Proxies',
      description: 'Voir les cartes proxy Magic: The Gathering les plus populaires.',
    },
    customCards: {
      title: 'Cartes Personnalisées - MTG Proxies',
      description: 'Créez vos propres cartes proxy personnalisées Magic: The Gathering.',
    },
    login: {
      title: 'Connexion - MTG Proxies',
      description: 'Connectez-vous à votre compte pour gérer vos cartes personnalisées, listes de decks et plus encore.',
    },
    register: {
      title: 'Inscription - MTG Proxies',
      description: 'Inscrivez-vous pour gérer vos cartes personnalisées, listes de decks et plus encore.',
    },
    termsOfService: {
      title: 'Conditions de Service - MTG Proxies',
      description: 'Lisez les conditions de service du site.',
    },
    privacyPolicy: {
      title: 'Politique de Confidentialité - MTG Proxies',
      description: 'Lisez la politique de confidentialité du site.',
    },
    account: {
      title: 'Compte - MTG Proxies',
      description: 'Gérez votre compte.',
    },
    proMembership: {
      title: 'Abonnement Pro - MTG Proxies',
      description: "Abonnez-vous à l'Abonnement Pro pour accéder à toutes les fonctionnalités du site.",
    },
    deckBuilder: {
      title: 'Constructeur de Deck - MTG Proxies',
      description: 'Créez et gérez vos decks Magic: The Gathering.',
    },
    deckEditor: {
      title: 'Éditeur de Deck - MTG Proxies',
      description: 'Créez et modifiez vos decks Magic: The Gathering.',
    },
    publicDecks: {
      title: 'Decks Publics - MTG Proxies',
      description: 'Parcourez et découvrez des decks MTG publics partagés par la communauté.',
    },
  },
  pro: {
    pricing: {
      title: 'Boostez Votre Expérience MTG Proxy',
      subtitle: "Passez au Pro et profitez d'une expérience premium sans publicité avec des fonctionnalités exclusives qui amènent vos proxies au niveau supérieur!",
      monthlyPlan: 'Boost Mensuel',
      yearlyPlan: 'Valeur Suprême Annuelle',
      monthlyPrice: '5,00 $ par mois',
      yearlyPrice: '40,00 $ par an',
      subscribeMonthly: 'Démarrer Maintenant',
      subscribeYearly: 'Économisez avec Annuel',
      signInToSubscribe: 'Connectez-vous pour Débloquer Pro',
      manageSubscription: 'Gérer Vos Avantages Pro',
      nextBillingDate: 'Votre prochaine date de paiement',
      benefitsEndDate: 'Accès Pro jusqu’au',
      viewInvoice: 'Voir le Reçu',
      changePlan: 'Changer Votre Plan',
      reactivate: 'Restaurer Vos Avantages Pro',
      active: 'Actif et Florissant',
      cancelled: 'Annulé',
      freeTrial: 'Essai Gratuit',
      freeTrialEnds: "L'Essai Gratuit se Termine",
      startFreeTrial: "Commencer l'Essai Gratuit de 7 Jours",
      signInForFreeTrial: "Connectez-vous pour l'Essai Gratuit de 7 Jours",
      current: 'Actuel',
      subscribe: "S'abonner",
    },
    benefits: {
      title: 'Pourquoi Choisir Pro avec MTG Proxies ?',
      subtitle: 'Rejoignez des centaines de joueurs qui ont amélioré leur expérience de proxies avec ces fonctionnalités Pro exclusives !',
      noAds: {
        title: 'Expérience Pure Sans Publicité',
        description: "Adieu les interruptions ! Profitez d'une interface impeccable sans publicités, bannières, ni pop-ups. L'expérience MTG à l'état pur.",
      },
      darkMode: {
        title: 'Mode Sombre Épique',
        description: "Doux pour vos yeux et élégant ! Parfait pour les noctambules et les sessions marathon de proxies dans n'importe quelle condition d'éclairage.",
      },
      advancedSearch: {
        title: 'Magie de Recherche Pro',
        description: 'Trouvez exactement ce dont vous avez besoin avec des filtres puissants ! Filtrez par identité de couleur, rareté, type de carte et plus pour construire le deck parfait.',
      },
      multipleDecks: {
        title: 'Création Illimitée de Decks',
        description: 'Paradis pour constructeurs de decks ! Créez et sauvegardez autant de decks que vous le souhaitez – du casual fun aux machines compétitives puissantes.',
      },
      futureFeatures: {
        title: 'Premier Accès aux Nouvelles Fonctionnalités',
        description: "À la pointe de l'innovation ! Les membres Pro ont toujours la primeur sur les nouvelles fonctionnalités passionnantes dès leur sortie.",
      },
      cancelAnytime: {
        title: 'Zéro Risque, Tous les Avantages',
        description: "Liberté totale ! Pas de contrats à long terme ni de frais cachés. Annulez d'un simple clic quand vous le souhaitez.",
      },
      prioritySupport: {
        title: 'Accès Support VIP',
        description: "Évitez l'attente ! Vos questions et préoccupations reçoivent un traitement prioritaire avec notre équipe de support dédiée.",
      },
    },
    features: {
      unlimitedDecks: 'Création illimitée de decks',
      noAds: 'Expérience de proxies sans publicité',
      prioritySupport: 'Accès au support VIP',
      darkMode: 'Interface mode sombre élégante',
      advancedSearch: 'Filtres et outils Pro de recherche',
      futureFeatures: 'Fonctionnalités exclusives à venir',
      cancelAnytime: 'Annulation sans tracas',
    },
    promotion: {
      title: 'Libérez la Création Illimitée de Decks',
      description: 'Brisez les limites ! Passez à Pro maintenant et construisez autant de decks que votre imagination permet.',
      customizeTitle: 'Transformez vos Cartes avec la Magie de l’IA',
      customizeDescription: "Passez à Pro et créez jusqu'à 5 variations personnalisées de cartes quotidiennement grâce à notre technologie IA de pointe !",
      banner: 'Essayez Pro gratuitement pendant 7 jours ! Sans publicités, mode sombre, decks illimités et plus encore.',
      tryNow: 'Essayer Maintenant',
    },
    account: {
      title: 'Votre Abonnement Pro',
      description: 'Gérez vos avantages premium',
      subscribeToPro: 'Passez à Pro Maintenant',
    },
  },
  auth: {
    signIn: {
      title: 'Connectez-vous à votre compte',
      withGoogle: 'Continuer avec Google',
      withEmail: 'Ou continuer avec',
      sendMagicLink: 'Envoyer le Lien Magique',
      noAccount: "Vous n'avez pas de compte ?",
      checkEmail: 'Vérifiez votre e-mail pour obtenir un lien de connexion',
      errors: {
        failed: 'Échec de la connexion',
        failedGoogle: 'Échec de la connexion avec Google',
        failedEmail: 'Échec de la connexion par e-mail',
        noEmail: 'Aucun e-mail trouvé',
      },
      terms: 'En me connectant, je confirme avoir lu et accepté les',
    },
    register: {
      title: 'Créez votre compte',
      haveAccount: 'Vous avez déjà un compte ?',
      terms: 'En créant un compte, je confirme avoir lu et accepté les',
    },
    common: {
      termsOfService: 'Conditions de Service',
      privacyPolicy: 'Politique de Confidentialité',
      and: 'et',
    },
  },
  home: {
    hero: {
      title: 'Proxy',
      rotatingWords: ['cartes', 'jetons', 'emblèmes', 'planeswalkers', 'commandants', 'terrains', 'créatures', 'artefacts', 'enchantements', 'éphémères'],
      description: 'MTG Proxies vous aide à créer des proxies de cartes Magic: The Gathering pour vos decks casual gratuitement !',
      search: {
        cards: {
          tab: 'Cartes',
          placeholder: 'Rechercher une carte Magic...',
          error: 'Échec du chargement des cartes aléatoires',
        },
        sets: {
          tab: 'Éditions',
          placeholder: 'Rechercher une édition Magic...',
        },
        tokens: {
          tab: 'Jetons',
          placeholder: 'Rechercher un jeton Magic...',
        },
        button: 'Rechercher',
        hint: {
          cards: 'Appuyez sur Entrée ou cliquez sur Rechercher pour trouver vos cartes',
          sets: 'Appuyez sur Entrée ou cliquez sur Rechercher pour trouver vos éditions',
          tokens: 'Appuyez sur Entrée ou cliquez sur Rechercher pour trouver vos jetons',
        },
        aria: {
          searchIcon: 'Icône de recherche',
          searchInput: {
            cards: 'Rechercher des cartes',
            sets: 'Rechercher des éditions',
            tokens: 'Rechercher des jetons',
          },
        },
      },
      images: {
        cardAlt: 'Image de carte Magic',
      },
      processedCount: '🎉 Nous avons traité plus de {{count}} proxies gratuites pour la communauté ! 🎉',
    },
    features: {
      section1: {
        badge: 'Imprimer des Proxies',
        title: 'Imprimer des Cartes Proxy MTG',
        subtitle: "Ajoutez des cartes à votre liste d'impression, imprimez-les et utilisez-les dans vos decks dès aujourd'hui !",
        features: [
          {
            name: "Ajoutez des cartes à votre liste d'impression",
            description: "Recherchez n'importe quelle carte via la page de Recherche ou parcourez les éditions et ajoutez les cartes souhaitées à votre liste d'impression.",
          },
          {
            name: 'Imprimez vos cartes',
            description: "Une fois que vous avez ajouté toutes les cartes souhaitées à votre liste d'impression, vous pouvez les imprimer et les utiliser dans vos decks.",
          },
          {
            name: 'Utilisez-les dans vos decks',
            description: "Une fois vos cartes imprimées, vous pouvez les utiliser dans vos decks. Découpez-les et collez-les sur un terrain de base ou utilisez une pochette avec un terrain de base à l'intérieur.",
          },
        ],
        images: {
          cartScreenshot: "Capture d'écran du panier montrant comment ajouter des cartes à votre liste d'impression",
        },
      },
      section2: {
        badge: 'Constructeur de Deck',
        title: 'Construisez et gérez des Decks Magic: The Gathering',
        subtitle: 'Créez et gérez vos propres decks, ou parcourez les decks publics créés par la communauté.',
        features: [
          {
            name: 'Gérez vos Decks MTG',
            description: 'Tous les utilisateurs peuvent créer et gérer un deck MTG. Les membres Pro peuvent créer un nombre illimité de decks.',
          },
          {
            name: 'Imprimez vos cartes',
            description: "Une fois que vous avez ajouté toutes les cartes souhaitées à votre deck, vous pouvez les ajouter à votre Liste d'Impression en une seule fois.",
          },
          {
            name: 'Parcourez les Decks Publics',
            description: "Ajoutez des decks complets créés par la communauté à votre Liste d'Impression pour tester facilement de nouveaux decks.",
          },
        ],
        images: {
          deckBuilderScreenshot: 'Interface du Constructeur de Deck montrant comment gérer vos decks',
        },
      },
    },
  },
  footer: {
    copyright: 'MTG Proxies, Tous droits réservés.',
    disclaimer: "MTG Proxies n'est pas produit, approuvé, soutenu ou affilié à Wizards of the Coast.",
    affiliate: 'En tant que partenaire affilié de TCGplayer, MTG Proxies peut gagner une commission sur les achats éligibles.',
    builtBy: 'Créé par',
    minnerLabs: 'Minner Labs',
    privacyPolicy: 'Politique de Confidentialité',
    termsOfService: "Conditions d'Utilisation",
    support: 'Support',
  },
  sort: {
    nameAsc: 'Nom (A à Z)',
    nameDesc: 'Nom (Z à A)',
    cmcAsc: 'Coût de Mana (Bas à Haut)',
    cmcDesc: 'Coût de Mana (Haut à Bas)',
    usdAsc: 'Prix (Bas à Haut)',
    usdDesc: 'Prix (Haut à Bas)',
    rarityAsc: 'Rareté (Commune à Mythique)',
    rarityDesc: 'Rareté (Mythique à Commune)',
    releasedDesc: 'Date de Sortie (Plus Récent)',
    releasedAsc: 'Date de Sortie (Plus Ancien)',
  },
  pages: {
    search: {
      heading: 'Recherche',
    },
    sets: {
      heading: 'Éditions de Cartes',
      latestSets: 'Dernières Éditions',
      upcomingSets: 'Éditions à Venir',
      allSets: 'Toutes les Éditions',
      noResults: 'Aucun résultat trouvé.',
    },
    set: {
      notFound: 'Édition non trouvée.',
      noCards: 'Aucune carte trouvée dans cette édition.',
      addAll: "Ajouter {{count}} cartes à la Liste d'Impression",
      addedToList: "{{count}} cartes ajoutées à la Liste d'Impression",
    },
    tokens: {
      heading: 'Jetons',
    },
    emblems: {
      heading: 'Emblèmes',
    },
    import: {
      heading: "Importer des cartes dans la Liste d'Impression",
      description: "Saisissez les noms de toutes les cartes que vous souhaitez ajouter à la Liste d'Impression.",
    },
    topProxies: {
      heading: 'Top Proxies',
      description: 'Voici les 50 cartes proxy Magic: The Gathering les plus populaires sur MTG Proxies.',
    },
  },
  languages: {
    default: 'Par défaut',
    any: 'Tous',
    english: 'Anglais',
    spanish: 'Espagnol',
    french: 'Français',
    german: 'Allemand',
    italian: 'Italien',
    portuguese: 'Portugais',
    japanese: 'Japonais',
    korean: 'Coréen',
    russian: 'Russe',
    simplified_chinese: 'Chinois simplifié',
    traditional_chinese: 'Chinois traditionnel',
    hebrew: 'Hébreu',
    latin: 'Latin',
    ancient_greek: 'Grec ancien',
    arabic: 'Arabe',
    sanskrit: 'Sanskrit',
    phyrexian: 'Phyrexian',
    quenya: 'Quenya',
  },
  filters: {
    show: '✨ Afficher les filtres Pro',
    hide: '✨ Masquer les filtres Pro',
  },
  search: {
    showing_start: 'Affichage de',
    showing_to: 'à',
    showing_of: 'sur',
    showing_results: 'résultats',
    noResults: 'Aucun résultat trouvé.',
    useSearchTip: 'Utilisez la recherche en haut de la page pour chercher une carte.',
  },
  advancedFilters: {
    colors: 'Couleurs',
    rarity: 'Rareté',
    cardTypes: 'Types de Carte',
    format: 'Format',
    manaValue: 'Valeur de Mana',
    cardName: 'Nom de Carte',
    cardText: 'Texte de Carte',
    specialCharacteristics: 'Caractéristiques Spéciales',
    activeFilters: 'Filtres Actifs',
    clearAll: 'Tout Effacer',
    applyFilters: 'Appliquer les Filtres',
    searchCardName: 'Rechercher un nom de carte...',
    searchCardText: 'Rechercher un texte de carte...',
    namePrefix: 'Nom : ',
    manaPrefix: 'Mana : ',
    textPrefix: 'Texte : ',
    removeFromFilter: '✕',
    selectLanguage: 'Sélectionner la langue',
  },
  card: {
    addToPrintList: "Ajouter à la liste d'impression",
    removeFromPrintList: "Retirer de la liste d'impression",
    cardsInPrintList: "carte dans la liste d'impression",
    cardsInPrintListPlural: "cartes dans la liste d'impression",
    buyOn: 'Acheter sur TCGplayer',
    buyFor: 'Acheter pour ',
    viewDetails: 'Voir les détails',
    type: 'Type',
    manaCost: 'Coût de mana',
    rarity: 'Rareté',
    artist: 'Artiste',
    collectorNumber: 'Numéro de collection',
    oracleText: 'Texte Oracle',
    youMightAlsoLike: 'Vous pourriez aussi aimer...',
    noRecommendations: 'Aucune recommandation disponible pour le moment.',
    details: 'Détails',
    rulings: 'Règles',
    legality: 'Légalité',
    share: 'Partager',
    favorite: 'Favori',
    noRulings: 'Aucune règle disponible pour cette carte.',
    noLegalityInfo: 'Aucune information de légalité disponible pour cette carte.',
    published: 'Publié',
    legal: 'Légal',
    restricted: 'Restreint',
    banned: 'Banni',
    format: 'Format',
    status: 'Statut',
    viewMore: 'Voir plus',
    viewLess: 'Voir moins',
    customizeWithAI: "Personnaliser avec l'IA",
    otherVersions: 'Impressions uniques',
    showUniqueVersions: 'Afficher les impressions uniques',
    hideUniqueVersions: 'Masquer les impressions uniques',
  },
  cart: {
    title: "Liste d'impression",
    closePanel: 'Fermer le panneau',
    empty: "Aucun proxy dans la liste d'impression.",
    addPadding: 'Ajouter de la marge',
    paperSize: 'Taille du papier',
    scale: 'Échelle',
    clearCardList: 'Vider la liste',
    print: 'Imprimer',
    success: 'Succès !',
  },
  setsList: {
    showing: 'Affichage de',
    to: 'à',
    of: 'sur',
    results: 'résultats',
    searchPlaceholder: 'Rechercher...',
  },
  customizeCardModal: {
    title: 'Personnaliser la Carte avec l\'IA',
    description: 'Décrivez comment vous souhaitez personnaliser cette carte. L\'IA générera une nouvelle version tout en conservant le cadre et la structure de la carte.',
    buttonText: 'Personnaliser avec l\'IA',
    placeholder: 'Décrivez votre variation personnalisée de carte...',
    generateButton: 'Générer une Carte Personnalisée',
    generatingButton: 'Génération en cours...',
    addToPrintListButton: 'Ajouter à la Liste d\'Impression',
    successMessage: 'Carte personnalisée ajoutée à la liste d\'impression',
    remainingGenerationsOne: '{{count}} génération restante aujourd\'hui',
    remainingGenerationsOther: '{{count}} générations restantes aujourd\'hui',
    error: {
      failed: 'Échec de la génération de la carte personnalisée',
    },
  },
};

export default translations;
