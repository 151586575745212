const translations = {
  common: {
    search: 'Suchen',
    signIn: 'Anmelden',
    register: 'Registrieren',
    myAccount: 'Mein Konto',
    support: 'Support',
    followUs: 'Folgen Sie uns auf X/Twitter',
    proMembership: 'Pro-Mitgliedschaft',
    new: 'Neu',
    proMember: 'Pro-Mitglied',
    upgrade: 'Upgrade',
    subscribe: 'Abonnieren',
    manage: 'Verwalten',
    cancel: 'Abbrechen',
    close: 'Schließen',
    or: 'Oder',
    email: 'E-Mail-Adresse',
    continue: 'Fortfahren',
    submit: 'Absenden',
    billing: 'Abrechnung',
    sort: 'Sortieren',
    language: 'Sprache',
    selectLanguage: 'Sprache auswählen',
    searchPlaceholder: 'Suchen...',
    cards: 'Karten',
    previous: 'Zurück',
    next: 'Weiter',
    pagination: 'Seitennummerierung',
    back: 'Zurück',
    backToSets: 'Zurück zu Sets',
    error: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
    home: 'Startseite',
    sets: 'Sets',
  },
  theme: {
    light: 'Hell',
    dark: 'Dunkel',
    toggle: 'Theme umschalten',
  },
  cta: {
    title: 'Bereit loszulegen?',
    subtitle: 'Beginnen Sie noch heute mit dem Erstellen von Karten-Proxies.',
    searchByCard: 'Nach Karte suchen',
    searchBySet: 'Nach Set suchen',
  },
  nav: {
    proxyCards: 'Proxy-Karten',
    sets: 'Sets',
    tokens: 'Spielmarken',
    emblems: 'Embleme',
    import: 'Importieren',
    topProxies: 'Top-Proxies',
    customCards: 'Eigene Karten',
    deckBuilder: 'Deck-Builder',
    buildDeck: 'Deck erstellen',
    publicDecks: 'Öffentliche Decks',
    favoriteDeck: 'Meine Lieblings-Decks',
    articles: 'Artikel',
  },
  seo: {
    home: {
      title: 'MTG Proxies - Proxy Magic: The Gathering Karten',
      description: 'Zugriff auf eine umfangreiche Bibliothek von Magic: The Gathering Proxy-Karten. Einfach MTG-Kartenbilder für Casual Play ansehen und drucken. Beginnen Sie jetzt mit dem Bau Ihres Traumdecks!',
    },
    sets: {
      title: 'Kartensets - MTG Proxies',
      description: 'Durchsuchen Sie alle MTG-Sets und drucken Sie Proxies für Ihr Deck.',
    },
    tokens: {
      title: 'Spielmarken - MTG Proxies',
      description: 'Verbessern Sie Ihre MTG-Spiele mit verschiedenen Proxy-Spielmarken.',
    },
    emblems: {
      title: 'Embleme - MTG Proxies',
      description: 'Drucken Sie MTG-Emblem-Proxykarten für Ihr Deck.',
    },
    search: {
      title: 'MTG-Karten suchen - MTG Proxies',
      description: 'Finden und drucken Sie MTG-Proxykarten mühelos. Nutzen Sie unser Suchwerkzeug, um auf eine umfangreiche Datenbank zuzugreifen und verbessern Sie Ihr Magic: The Gathering Deck mit Proxies.',
    },
    support: {
      title: 'Support - MTG Proxies',
      description: 'Wenn Sie Fragen, Feedback haben oder eine Spielmarke/ein Emblem hinzufügen möchten, senden Sie uns eine E-Mail.',
    },
    articles: {
      title: 'Artikel - MTG Proxies',
      description: 'Artikel über Magic: The Gathering und Proxy-Karten.',
    },
    import: {
      title: 'Importieren - MTG Proxies',
      description: 'Importieren Sie eine Liste von Karten, um Ihre Magic: The Gathering Proxies zu drucken.',
    },
    topProxies: {
      title: 'Top-Proxies - MTG Proxies',
      description: 'Sehen Sie die beliebtesten Magic: The Gathering Proxy-Karten.',
    },
    customCards: {
      title: 'Eigene Karten - MTG Proxies',
      description: 'Erstellen Sie Ihre eigenen Magic: The Gathering Proxy-Karten.',
    },
    login: {
      title: 'Anmelden - MTG Proxies',
      description: 'Melden Sie sich bei Ihrem Konto an, um Ihre eigenen Karten, Decklisten und mehr zu verwalten.',
    },
    register: {
      title: 'Registrieren - MTG Proxies',
      description: 'Registrieren Sie sich, um Ihre eigenen Karten, Decklisten und mehr zu verwalten.',
    },
    termsOfService: {
      title: 'Nutzungsbedingungen - MTG Proxies',
      description: 'Lesen Sie die Nutzungsbedingungen der Website.',
    },
    privacyPolicy: {
      title: 'Datenschutzerklärung - MTG Proxies',
      description: 'Lesen Sie die Datenschutzerklärung der Website.',
    },
    account: {
      title: 'Konto - MTG Proxies',
      description: 'Verwalten Sie Ihr Konto.',
    },
    proMembership: {
      title: 'Pro-Mitgliedschaft - MTG Proxies',
      description: 'Abonnieren Sie die Pro-Mitgliedschaft, um Zugang zu allen Funktionen der Website zu erhalten.',
    },
    deckBuilder: {
      title: 'Deck-Builder - MTG Proxies',
      description: 'Erstellen und verwalten Sie Ihre Magic: The Gathering Decks.',
    },
    deckEditor: {
      title: 'Deck-Editor - MTG Proxies',
      description: 'Erstellen und bearbeiten Sie Ihre Magic: The Gathering Decks.',
    },
    publicDecks: {
      title: 'Öffentliche Decks - MTG Proxies',
      description: 'Durchsuchen und entdecken Sie öffentliche MTG-Decks, die von der Community geteilt wurden.',
    },
  },
  pro: {
    pricing: {
      title: 'Steigern Sie Ihr MTG Proxy-Erlebnis',
      subtitle: 'Werden Sie Pro und genießen Sie ein werbefreies Premium-Erlebnis mit exklusiven Funktionen, die Ihre Proxies auf die nächste Stufe heben!',
      monthlyPlan: 'Monatlicher Power-Boost',
      yearlyPlan: 'Jährlicher Spar-Vorteil',
      monthlyPrice: '5,00 $ pro Monat',
      yearlyPrice: '40,00 $ pro Jahr',
      subscribeMonthly: 'Jetzt Starten',
      subscribeYearly: 'Jährlich Sparen',
      signInToSubscribe: 'Anmelden und Pro freischalten',
      manageSubscription: 'Ihre Pro-Vorteile verwalten',
      nextBillingDate: 'Ihr nächstes Zahlungsdatum',
      benefitsEndDate: 'Pro-Zugang bis zum',
      viewInvoice: 'Beleg anzeigen',
      changePlan: 'Ihren Plan wechseln',
      reactivate: 'Pro-Vorteile wiederherstellen',
      active: 'Aktiv und Erfolgreich',
      cancelled: 'Gekündigt',
      freeTrial: 'Kostenlose Testversion',
      freeTrialEnds: 'Kostenlose Testversion endet',
      startFreeTrial: '7-tägige kostenlose Testversion starten',
      signInForFreeTrial: 'Anmelden für 7-tägige kostenlose Testversion',
      current: 'Aktuell',
      subscribe: 'Abonnieren',
    },
    benefits: {
      title: 'Warum Pro mit MTG Proxies wählen?',
      subtitle: 'Schließen Sie sich Hunderten von Spielern an, die ihr Proxy-Erlebnis mit diesen exklusiven Pro-Funktionen verbessert haben!',
      noAds: {
        title: 'Sauberes, werbefreies Erlebnis',
        description: 'Verabschieden Sie sich von Unterbrechungen! Genießen Sie eine makellose Oberfläche ohne Anzeigen, Banner oder Pop-ups. Pures MTG-Proxy-Vergnügen.',
      },
      darkMode: {
        title: 'Epischer Dunkelmodus',
        description: 'Angenehm für die Augen und stilvoll! Perfekt für Nachteulen und Marathon-Proxy-Sessions bei jeder Lichtsituation.',
      },
      advancedSearch: {
        title: 'Pro-Level Suchmagie',
        description: 'Finden Sie genau, was Sie brauchen, mit leistungsstarken Filtern! Filtern Sie nach Farbidentität, Seltenheit, Kartentyp und mehr, um das perfekte Deck zu bauen.',
      },
      multipleDecks: {
        title: 'Unbegrenzter Deck-Bau',
        description: 'Ein Paradies für Deck-Builder! Erstellen und speichern Sie so viele Decks, wie Sie möchten – vom Casual-Spaß bis zu wettbewerbsfähigen Powerhouses.',
      },
      futureFeatures: {
        title: 'Erster Zugang zu neuen Funktionen',
        description: 'Seien Sie an der Spitze der Entwicklung! Pro-Mitglieder erhalten immer zuerst Zugriff auf spannende neue Funktionen, sobald sie veröffentlicht werden.',
      },
      cancelAnytime: {
        title: 'Null Risiko, voller Gewinn',
        description: 'Volle Freiheit! Keine langfristigen Verträge oder versteckten Gebühren. Kündigen Sie mit einem Klick, wann immer Sie möchten.',
      },
      prioritySupport: {
        title: 'VIP-Support-Zugang',
        description: 'Überspringen Sie die Warteschlange! Ihre Fragen und Anliegen erhalten bevorzugte Behandlung durch unser engagiertes Support-Team.',
      },
    },
    features: {
      unlimitedDecks: 'Unbegrenzte Deck-Erstellung',
      noAds: 'Werbefreies Proxy-Erlebnis',
      prioritySupport: 'VIP-Support-Zugang',
      darkMode: 'Stilvolle Dunkelmodus-Oberfläche',
      advancedSearch: 'Pro-Suchfilter & Werkzeuge',
      futureFeatures: 'Exklusive kommende Funktionen',
      cancelAnytime: 'Unkomplizierte Kündigung',
    },
    promotion: {
      title: 'Entfesseln Sie unbegrenzte Deck-Erstellung',
      description: 'Brechen Sie die Grenzen! Upgraden Sie jetzt auf Pro und bauen Sie so viele Decks, wie Ihre Fantasie erlaubt.',
      customizeTitle: 'Transformieren Sie Karten mit KI-Magie',
      customizeDescription: 'Upgraden Sie auf Pro und erstellen Sie täglich bis zu 5 individuelle Kartenvariationen mit unserer fortschrittlichen KI-Technologie!',
      banner: 'Testen Sie Pro 7 Tage kostenlos! Keine Werbung, Dunkelmodus, unbegrenzte Decks und mehr.',
      tryNow: 'Jetzt Testen',
    },
    account: {
      title: 'Ihre Pro-Mitgliedschaft',
      description: 'Verwalten Sie Ihre Premium-Vorteile',
      subscribeToPro: 'Jetzt auf Pro upgraden',
    },
  },
  auth: {
    signIn: {
      title: 'Melden Sie sich bei Ihrem Konto an',
      withGoogle: 'Mit Google fortfahren',
      withEmail: 'Oder fortfahren mit',
      sendMagicLink: 'Magic Link senden',
      noAccount: 'Noch kein Konto?',
      checkEmail: 'Überprüfen Sie Ihre E-Mail für einen Anmeldelink',
      errors: {
        failed: 'Anmeldung fehlgeschlagen',
        failedGoogle: 'Anmeldung mit Google fehlgeschlagen',
        failedEmail: 'Anmeldung per E-Mail fehlgeschlagen',
        noEmail: 'Keine E-Mail gefunden',
      },
      terms: 'Mit der Anmeldung bestätige ich, dass ich die',
    },
    register: {
      title: 'Erstellen Sie Ihr Konto',
      haveAccount: 'Haben Sie bereits ein Konto?',
      terms: 'Mit der Erstellung eines Kontos bestätige ich, dass ich die',
    },
    common: {
      termsOfService: 'Nutzungsbedingungen',
      privacyPolicy: 'Datenschutzerklärung',
      and: 'und',
    },
  },
  home: {
    hero: {
      title: 'Proxy',
      rotatingWords: ['Karten', 'Spielmarken', 'Embleme', 'Planeswalker', 'Kommandeure', 'Länder', 'Kreaturen', 'Artefakte', 'Verzauberungen', 'Spontanzauber'],
      description: 'MTG Proxies hilft dir dabei, Magic: The Gathering Proxy-Karten für deine Casual-Decks kostenlos zu erstellen!',
      search: {
        cards: {
          tab: 'Karten',
          placeholder: 'Nach Magic-Karten suchen...',
          error: 'Fehler beim Laden zufälliger Karten',
        },
        sets: {
          tab: 'Editionen',
          placeholder: 'Nach Magic-Editionen suchen...',
        },
        tokens: {
          tab: 'Spielmarken',
          placeholder: 'Nach Magic-Spielmarken suchen...',
        },
        button: 'Suchen',
        hint: {
          cards: 'Drücke Enter oder klicke auf Suchen, um deine Karten zu finden',
          sets: 'Drücke Enter oder klicke auf Suchen, um deine Editionen zu finden',
          tokens: 'Drücke Enter oder klicke auf Suchen, um deine Spielmarken zu finden',
        },
        aria: {
          searchIcon: 'Suchsymbol',
          searchInput: {
            cards: 'Nach Karten suchen',
            sets: 'Nach Editionen suchen',
            tokens: 'Nach Spielmarken suchen',
          },
        },
      },
      images: {
        cardAlt: 'Magic-Kartenbild',
      },
      processedCount: '🎉 Wir haben über {{count}} kostenlose Proxies für die Community erstellt! 🎉',
    },
    features: {
      section1: {
        badge: 'Proxies Drucken',
        title: 'MTG Proxy-Karten Drucken',
        subtitle: 'Füge Karten deiner Druckliste hinzu, drucke sie aus und verwende sie noch heute in deinen Decks!',
        features: [
          {
            name: 'Füge Karten deiner Druckliste hinzu',
            description: 'Suche nach beliebigen Karten über die Suchseite oder durchsuche die Editionen und füge die gewünschten Karten deiner Druckliste hinzu.',
          },
          {
            name: 'Drucke deine Karten',
            description: 'Sobald du alle gewünschten Karten deiner Druckliste hinzugefügt hast, kannst du sie ausdrucken und in deinen Decks verwenden.',
          },
          {
            name: 'Verwende sie in deinen Decks',
            description: 'Sobald du deine Karten ausgedruckt hast, kannst du sie in deinen Decks verwenden. Schneide sie aus und klebe sie auf ein Standardland oder verwende eine Hülle mit einem Standardland darin.',
          },
        ],
        images: {
          cartScreenshot: 'Warenkorb-Screenshot zeigt, wie man Karten zur Druckliste hinzufügt',
        },
      },
      section2: {
        badge: 'Deck-Builder',
        title: 'Magic: The Gathering Decks Erstellen und Verwalten',
        subtitle: 'Erstelle und verwalte deine eigenen Decks oder durchstöbere öffentliche Decks der Community.',
        features: [
          {
            name: 'Verwalte deine MTG Decks',
            description: 'Alle Benutzer können ein MTG-Deck erstellen und verwalten. Pro-Mitglieder können unbegrenzt viele Decks erstellen.',
          },
          {
            name: 'Drucke deine Karten',
            description: 'Sobald du alle gewünschten Karten deinem Deck hinzugefügt hast, kannst du sie auf einmal deiner Druckliste hinzufügen.',
          },
          {
            name: 'Durchsuche Öffentliche Decks',
            description: 'Füge komplette Decks der Community deiner Druckliste hinzu, um neue Decks einfach zu testen.',
          },
        ],
        images: {
          deckBuilderScreenshot: 'Deck-Builder-Oberfläche zeigt, wie man Decks verwaltet',
        },
      },
    },
  },
  footer: {
    copyright: 'MTG Proxies, Alle Rechte vorbehalten.',
    disclaimer: 'MTG Proxies wird nicht von Wizards of the Coast produziert, unterstützt oder ist damit verbunden.',
    affiliate: 'Als TCGplayer-Affiliate-Partner kann MTG Proxies eine Provision für qualifizierte Käufe erhalten.',
    builtBy: 'Erstellt von',
    minnerLabs: 'Minner Labs',
    privacyPolicy: 'Datenschutzrichtlinie',
    termsOfService: 'Nutzungsbedingungen',
    support: 'Support',
  },
  sort: {
    nameAsc: 'Name (A bis Z)',
    nameDesc: 'Name (Z bis A)',
    cmcAsc: 'Manawert (Niedrig zu Hoch)',
    cmcDesc: 'Manawert (Hoch zu Niedrig)',
    usdAsc: 'Preis (Niedrig zu Hoch)',
    usdDesc: 'Preis (Hoch zu Niedrig)',
    rarityAsc: 'Seltenheit (Gewöhnlich zu Mythisch)',
    rarityDesc: 'Seltenheit (Mythisch zu Gewöhnlich)',
    releasedDesc: 'Erscheinungsdatum (Neueste zuerst)',
    releasedAsc: 'Erscheinungsdatum (Älteste zuerst)',
  },
  pages: {
    search: {
      heading: 'Suchen',
    },
    sets: {
      heading: 'Kartensets',
      latestSets: 'Neueste Sets',
      upcomingSets: 'Kommende Sets',
      allSets: 'Alle Sets',
      noResults: 'Keine Ergebnisse gefunden.',
    },
    set: {
      notFound: 'Set nicht gefunden.',
      noCards: 'Keine Karten in diesem Set gefunden.',
      addAll: '{{count}} Karten zur Druckliste hinzufügen',
      addedToList: '{{count}} Karten zur Druckliste hinzugefügt',
    },
    tokens: {
      heading: 'Spielmarken',
    },
    emblems: {
      heading: 'Embleme',
    },
    import: {
      heading: 'Karten zur Druckliste importieren',
      description: 'Geben Sie die Namen aller Karten ein, die Sie zur Druckliste hinzufügen möchten.',
    },
    topProxies: {
      heading: 'Top-Proxies',
      description: 'Hier sind die 50 beliebtesten Magic: The Gathering Proxy-Karten auf MTG Proxies.',
    },
  },
  languages: {
    default: 'Standard',
    any: 'Alle',
    english: 'Englisch',
    spanish: 'Spanisch',
    french: 'Französisch',
    german: 'Deutsch',
    italian: 'Italienisch',
    portuguese: 'Portugiesisch',
    japanese: 'Japanisch',
    korean: 'Koreanisch',
    russian: 'Russisch',
    simplified_chinese: 'Vereinfachtes Chinesisch',
    traditional_chinese: 'Traditionelles Chinesisch',
    hebrew: 'Hebräisch',
    latin: 'Latein',
    ancient_greek: 'Altgriechisch',
    arabic: 'Arabisch',
    sanskrit: 'Sanskrit',
    phyrexian: 'Phyrexianisch',
    quenya: 'Quenya',
  },
  filters: {
    show: '✨ Pro-Filter anzeigen',
    hide: '✨ Pro-Filter ausblenden',
  },
  search: {
    showing_start: 'Zeige',
    showing_to: 'bis',
    showing_of: 'von',
    showing_results: 'Ergebnissen',
    noResults: 'Keine Ergebnisse gefunden.',
    useSearchTip: 'Verwenden Sie die Suche oben auf der Seite, um nach einer Karte zu suchen.',
  },
  advancedFilters: {
    colors: 'Farben',
    rarity: 'Seltenheit',
    cardTypes: 'Kartentypen',
    format: 'Format',
    manaValue: 'Manawert',
    cardName: 'Kartenname',
    cardText: 'Kartentext',
    specialCharacteristics: 'Besondere Eigenschaften',
    activeFilters: 'Aktive Filter',
    clearAll: 'Alle löschen',
    applyFilters: 'Filter anwenden',
    searchCardName: 'Kartenname suchen...',
    searchCardText: 'Kartentext suchen...',
    namePrefix: 'Name: ',
    manaPrefix: 'Mana: ',
    textPrefix: 'Text: ',
    removeFromFilter: '✕',
    selectLanguage: 'Sprache auswählen',
  },
  card: {
    addToPrintList: 'Zur Druckliste hinzufügen',
    removeFromPrintList: 'Von Druckliste entfernen',
    cardsInPrintList: 'Karte in Druckliste',
    cardsInPrintListPlural: 'Karten in Druckliste',
    buyOn: 'Auf TCGplayer kaufen',
    buyFor: 'Kaufen für ',
    viewDetails: 'Details anzeigen',
    type: 'Typ',
    manaCost: 'Manakosten',
    rarity: 'Seltenheit',
    artist: 'Künstler',
    collectorNumber: 'Sammlernummer',
    oracleText: 'Oracle-Text',
    youMightAlsoLike: 'Das könnte dir auch gefallen...',
    noRecommendations: 'Keine Empfehlungen verfügbar.',
    details: 'Details',
    rulings: 'Regeln',
    legality: 'Legalität',
    share: 'Teilen',
    favorite: 'Favorit',
    noRulings: 'Keine Regeln für diese Karte verfügbar.',
    noLegalityInfo: 'Keine Legalitätsinformationen für diese Karte verfügbar.',
    published: 'Veröffentlicht',
    legal: 'Legal',
    restricted: 'Eingeschränkt',
    banned: 'Verboten',
    format: 'Format',
    status: 'Status',
    viewMore: 'Mehr anzeigen',
    viewLess: 'Weniger anzeigen',
    customizeWithAI: 'Mit KI anpassen',
    otherVersions: 'Einzigartige Drucke',
    showUniqueVersions: 'Einzigartige Drucke anzeigen',
    hideUniqueVersions: 'Einzigartige Drucke ausblenden',
  },
  cart: {
    title: 'Druckliste',
    closePanel: 'Panel schließen',
    empty: 'Keine Proxies in der Druckliste.',
    addPadding: 'Abstand hinzufügen',
    paperSize: 'Papiergröße',
    scale: 'Skalierung',
    clearCardList: 'Liste leeren',
    print: 'Drucken',
    success: 'Erfolg!',
  },
  setsList: {
    showing: 'Zeige',
    to: 'bis',
    of: 'von',
    results: 'Ergebnissen',
    searchPlaceholder: 'Suchen...',
  },
  customizeCardModal: {
    title: 'Karte mit KI anpassen',
    description: 'Beschreiben Sie, wie Sie diese Karte anpassen möchten. Die KI wird eine neue Version generieren, während der Kartenrahmen und die Struktur beibehalten werden.',
    buttonText: 'Mit KI anpassen',
    placeholder: 'Beschreiben Sie Ihre benutzerdefinierte Kartenvariation...',
    generateButton: 'Benutzerdefinierte Karte generieren',
    generatingButton: 'Generiere...',
    addToPrintListButton: 'Zur Druckliste hinzufügen',
    successMessage: 'Benutzerdefinierte Karte zur Druckliste hinzugefügt',
    remainingGenerationsOne: '{{count}} verbleibende Generierung heute',
    remainingGenerationsOther: '{{count}} verbleibende Generierungen heute',
    error: {
      failed: 'Fehler beim Generieren der benutzerdefinierten Karte',
    },
  },
};

export default translations;
